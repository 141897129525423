// React
import React, { useCallback, useState, useMemo } from "react";
// Helpers
import { get } from "@mefisto/utils";
// Framework
import { Box, ColorPicker, ModalDialog } from "ui/components";
import { useTranslate } from "localization/hooks";
import { ChipInput } from "form/input";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  label,
  fullWidth,
  form: { touched, errors, setFieldValue },
  field: { name, value },
}) => {
  // Framework
  const { t } = useTranslate();
  // State
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  // Memo
  const errorText = useMemo(() => {
    return get(errors, name);
  }, [errors, name]);
  const hasError = useMemo(() => {
    return get(touched, name) && Boolean(errorText);
  }, [touched, name, errorText]);
  // Handlers
  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);
  const handleSelection = useCallback(
    (value) => {
      setFieldValue(name, value);
      setOpen(false);
    },
    [setFieldValue, name]
  );
  const handleClear = useCallback(() => {
    setFieldValue(name, "");
  }, [setFieldValue, name]);
  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setFocused(true);
  }, []);
  // Render
  return (
    <>
      <ChipInput
        error={hasError ? errorText : null}
        value={value ? [{ color: value }] : []}
        label={label}
        fullWidth={fullWidth}
        focused={focused}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onDelete={handleClear}
      />
      <ModalDialog
        open={open}
        title={t("core:form.colorField.colorPicker.title")}
        onClose={handleClose}
      >
        <Box maxWidth={296}>
          <ColorPicker
            open={open}
            selectedColor={value}
            onSelection={handleSelection}
            onClose={handleClose}
          />
        </Box>
      </ModalDialog>
    </>
  );
};

const FormColorField = (props) => (
  <FormField variant="outlined" fullWidth component={Component} {...props} />
);

export default FormColorField;
