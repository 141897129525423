// React
import React from "react";
// Framework
import { forwardType, App, Permission } from "@mefisto/web";
// Icon
import { PortalIcon } from "icons";
// Loader
import { Loader } from "loader";
// Navigation
import { paths as portalPaths } from "@plugins/portal";
import { paths as billingPaths } from "@plugins/billing";
// Permissions
import { portal as permissions } from "@plugins/portal";

export default forwardType(App, () => (
  <App
    enabled
    name="PORTAL"
    type="USER"
    title="Portal"
    color="#00b3e3"
    priority={1}
    icon={<PortalIcon />}
    loader={<Loader />}
    navigation={{
      basename: "portal",
      routes: {
        default: "/dashboard",
        notAuthorized: "/organization/get-started",
      },
      paths: {
        ...billingPaths,
        subscription: "/subscription",
        ...portalPaths,
        organization: "/organization",
        dashboard: "/dashboard",
      },
    }}
    resource={{
      source: "ui",
    }}
    permissions={[
      ...permissions(),
      Permission("successRate.query", "Can read success rate data"),
      Permission("note.query", "Can read note data"),
      Permission("note.mutation", "Can write note data"),
      Permission("player.query", "Can read player data"),
      Permission("statistic.query", "Can read statistic data"),
      Permission("statistic.mutation", "Can write statistic data"),
    ]}
  />
));
