// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import {
  classnames,
  makeStyles,
  alpha,
  Section,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Cloak,
} from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    width: `calc(100% - ${theme.spacing(4)}px)`,
    color: theme.palette.action.active,
    borderRadius: theme.radius.normal,
    transition: theme.transitions.create("background"),
    "&:hover": {
      backgroundColor: alpha(theme.palette.action.active, 0.1),
    },
  },
  selected: {
    backgroundColor: alpha(theme.palette.action.active, 0.1),
    color: theme.palette.action.active,
    "&:hover": {
      color: theme.palette.action.active,
      backgroundColor: alpha(theme.palette.action.active, 0.1),
    },
    "&:focus": {
      color: theme.palette.action.active,
      backgroundColor: alpha(theme.palette.action.active, 0.1),
    },
  },
  icon: {
    color: "inherit",
    marginRight: 0,
    padding: theme.spacing(1, 0),
    minWidth: theme.spacing(5),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderMenuItem = ({
  context = "item",
  value,
  title,
  path,
  icon,
  disabled,
  selected,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { navigation } = usePortal();
  // Handlers
  const handleSelection = useCallback(() => {
    navigation.goTo(path);
  }, [navigation, path]);
  // Render
  return (
    <Section context={context} value={value}>
      <Cloak variant="remove" enabled={disabled}>
        <ListItem
          button
          disableRipple
          className={classnames(classes.root, {
            [classes.selected]: selected,
          })}
          onClick={handleSelection}
        >
          {icon && (
            <ListItemIcon
              classes={{
                root: classes.icon,
              }}
            >
              {icon}
            </ListItemIcon>
          )}
          <Typography
            noWrap
            variant="subtitle2"
            color="inherit"
            display="block"
            component="div"
          >
            <ListItemText disableTypography primary={title} />
          </Typography>
        </ListItem>
      </Cloak>
    </Section>
  );
};

HeaderMenuItem.propTypes = {
  /**
   * Component context
   */
  context: PropTypes.string,
  /**
   * Component value
   */
  value: PropTypes.string,
  /**
   * Menu Item title
   */
  title: PropTypes.string,
  /**
   * Path used for navigation when the
   * user selects the menu item
   */
  path: PropTypes.string,
  /**
   * Menu item icon
   */
  icon: PropTypes.element,
  /**
   * Set to `true` when the menu item is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Set to `true` when the menu item is selected
   */
  selected: PropTypes.bool,
};

export default HeaderMenuItem;
