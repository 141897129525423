// React
import React, {
  cloneElement,
  isValidElement,
  useEffect,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { map } from "@mefisto/utils";
// Framework
import {
  makeStyles,
  Hidden,
  IconButton,
  Drawer,
  Grid,
  Spacer,
  Typography,
  Scrollable,
} from "ui";
import { usePortal } from "stack";
import { useMounted } from "hooks";
import { MenuRounded as MenuIcon, Close as CloseIcon } from "icon/material";
// Components
import HeaderItem from "../HeaderItem";
import HeaderMenuSection from "./components/HeaderMenuSection";
import HeaderMenuLink from "./components/HeaderMenuLink";
import HeaderMenuItem from "./components/HeaderMenuItem";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    // TODO: Take from layout props
    width: theme.sizes.dashboardDrawer.open,
    maxWidth: "100%",
    background: theme.palette.background.underlying,
  },
  title: {
    padding: theme.spacing(2, 1, 2, 3),
  },
  content: {
    width: "100%",
    height: "100%",
  },
  outer: {
    // Make the portal layout fill the whole screen.
    // We are using "dvh" unit, so it adopts to different layout
    // additions such as dynamic toolbars, etc.
    height: "100dvh",
    // Keep the "vh" as fallback for browser that do not support "dvh".
    fallbacks: [{ height: "100vh" }],
  },
  inner: {
    height: "100%",
  },
  plugin: {
    padding: theme.spacing(0, 2),
  },
  links: {
    marginLeft: theme.spacing(2),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderMenu = ({
  title,
  links,
  menu,
  menuPluginTop,
  menuPluginBottom,
  menuPluginLeading,
  menuPluginTrailing,
  elevation = 1,
}) => {
  // Framework
  const { navigation } = usePortal();
  const { callWhenMounted } = useMounted();
  // Styles
  const classes = useStyles();
  // State
  const [open, setOpen] = useState(false);
  // Handlers
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  // Effect
  useEffect(() => {
    return navigation.onChange(
      callWhenMounted(() => {
        setOpen(false);
      })
    );
  }, [callWhenMounted, navigation]);
  // Render
  return (
    <Hidden mdUp>
      <HeaderItem value="menu">
        <IconButton
          value="menu"
          color="inherit"
          edge="start"
          onClick={open ? handleClose : handleOpen}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          open={open}
          elevation={elevation}
          classes={{
            paper: classes.paper,
          }}
          onClose={handleClose}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            justifyContent="space-between"
            className={classes.outer}
          >
            <Grid flex item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                className={classes.inner}
              >
                {menuPluginLeading && <Grid item>{menuPluginLeading}</Grid>}
                <Grid flex item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    wrap="nowrap"
                    className={classes.content}
                  >
                    <div className={classes.title}>
                      <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography variant="h6">{title}</Typography>
                        </Grid>
                        <Grid item>
                          <IconButton value="close" onClick={handleClose}>
                            <CloseIcon color="action" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </div>
                    {menuPluginTop && (
                      <Grid item className={classes.plugin}>
                        {menuPluginTop}
                      </Grid>
                    )}
                    <Scrollable disableScrollX>
                      {links && (
                        <Grid container direction="column" spacing={4}>
                          {map(links, ({ title, links }, key) => (
                            <Grid item key={key}>
                              <Typography
                                gutterBottom
                                className={classes.links}
                                variant="subtitle2"
                              >
                                {title}
                              </Typography>
                              {map(links, ({ value, title, path }, key) => (
                                <HeaderMenuLink
                                  key={key}
                                  value={value}
                                  title={title}
                                  path={path}
                                />
                              ))}
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      <Grid item>
                        {isValidElement(menu) &&
                          cloneElement(menu, {
                            sectionComponent: <HeaderMenuSection />,
                            itemComponent: <HeaderMenuItem />,
                          })}
                      </Grid>
                    </Scrollable>
                    <Spacer />
                    {menuPluginBottom && (
                      <Grid item className={classes.plugin}>
                        {menuPluginBottom}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {menuPluginTrailing && <Grid item>{menuPluginTrailing}</Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </HeaderItem>
    </Hidden>
  );
};

HeaderMenu.propTypes = {
  title: PropTypes.any,
  links: PropTypes.array,
  menu: PropTypes.element,
  menuPluginTop: PropTypes.element,
  menuPluginBottom: PropTypes.element,
  menuPluginLeading: PropTypes.element,
  menuPluginTrailing: PropTypes.element,
  elevation: PropTypes.number,
};

export default HeaderMenu;
