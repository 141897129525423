// React
import React, {
  cloneElement,
  isValidElement,
  useState,
  useMemo,
  useCallback,
} from "react";
// Helpers
import {
  isEmpty,
  toString,
  filter,
  find,
  map,
  reduce,
  get,
} from "@mefisto/utils";
// Framework
import {
  Box,
  Tooltip,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "ui";
import { useTheme } from "theme";
import {
  ClearOutlined as ClearIcon,
  KeyboardArrowDownRounded as MoreIcon,
} from "icon/material";
import { usePortal } from "stack/core";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = (props) => {
  const {
    form,
    field,
    iconSize = 24,
    disabled,
    label,
    helperText,
    placeholder,
    ...rest
  } = props;
  const { touched, errors, isSubmitting } = form;
  const { name, value } = field;
  const fieldError = get(errors, name);
  const showError = get(touched, name) && !!fieldError;
  // Framework
  const { localization } = usePortal();
  // Styles
  const { radius } = useTheme();
  // State
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setLanguage] = useState(localization.current);
  // Memo
  const currentValue = useMemo(() => {
    return find(value, ({ code }) => code === language.code)?.value ?? "";
  }, [value, language]);
  // Callback
  const mapValue = useCallback(
    (newValue) => {
      if (!newValue) {
        return filter(value, ({ code }) => code !== language.code);
      }
      if (!find(value, ({ code }) => code === language.code)) {
        return [
          ...value,
          {
            __typename: "LocalizedString",
            code: language.code,
            value: newValue,
          },
        ];
      }
      return reduce(
        value,
        (result, value) => {
          result.push({
            ...value,
            ...(value.code === language.code && {
              value: newValue,
            }),
          });
          return result;
        },
        []
      );
    },
    [value, language]
  );
  // Handlers
  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);
  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);
  const handleClear = () => {
    form.setFieldValue(name, mapValue(null));
  };
  const handleChange = (event) => {
    const { value } = event.target;
    form.setFieldValue(name, mapValue(value));
  };
  const handleSelection = (language) => {
    setOpen(false);
    setLanguage(language);
  };
  const handleOpen = ({ currentTarget }) => {
    setOpen(true);
    setAnchorEl(currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <TextField
      fullWidth
      variant="outlined"
      name={name}
      value={currentValue}
      InputLabelProps={{ shrink: true }}
      label={label}
      placeholder={placeholder}
      helperText={showError ? fieldError : helperText}
      error={showError}
      disabled={disabled ?? isSubmitting}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              wrap="nowrap"
              spacing={1}
            >
              <Grid item>
                <IconButton onClick={handleOpen}>
                  <Box
                    overflow="hidden"
                    borderRadius={radius.rounded}
                    width={iconSize}
                    height={iconSize}
                  >
                    {language.icon}
                  </Box>
                </IconButton>
              </Grid>
              <Grid item>
                <Box mb={-0.5} ml={-1}>
                  <MoreIcon color="inherit" />
                </Box>
              </Grid>
            </Grid>
            <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
              <MenuList dense>
                {map(localization.languages, (language, key) => (
                  <MenuItem
                    dense
                    key={key}
                    onClick={() => handleSelection(language)}
                  >
                    {isValidElement(language.icon) && (
                      <ListItemIcon>
                        <Box
                          overflow="hidden"
                          borderRadius={radius.rounded}
                          fontSize={iconSize}
                          width={iconSize}
                          height={iconSize}
                        >
                          {cloneElement(language.icon, {
                            fontSize: "inherit",
                          })}
                        </Box>
                      </ListItemIcon>
                    )}
                    <ListItemText primary={language.title} />
                  </MenuItem>
                ))}
              </MenuList>
            </Popover>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            style={{
              visibility:
                (focus || hover) && !isEmpty(toString(value))
                  ? "visible"
                  : "hidden",
            }}
          >
            <Tooltip enterDelay={800} placement="bottom" title="Clear">
              <IconButton onClick={handleClear} tabIndex={-1}>
                <ClearIcon color="action" fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onChange={handleChange}
      {...rest}
    />
  );
};

const FormLocalizedTextField = (props) => {
  return <FormField component={Component} {...props} />;
};

export default FormLocalizedTextField;
