// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    borderRadius: theme.radius.rounded,
    cursor: "pointer",
    transition: theme.transitions.create("all"),
    "&:hover": {
      transform: "scale(1.2)",
    },
    border: "4px solid",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ColorItem = ({ color, selected, onSelection }) => {
  // Styles
  const classes = useStyles();
  // Handlers
  const handleSelection = useCallback(() => {
    onSelection(color);
  }, [onSelection, color]);
  // Render
  return (
    <div
      style={{ borderColor: color, ...(!selected && { background: color }) }}
      className={classes.root}
      onClick={handleSelection}
    ></div>
  );
};

ColorItem.propTypes = {
  color: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onSelection: PropTypes.func.isRequired,
};

export default ColorItem;
