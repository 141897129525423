// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { get } from "@mefisto/utils";
// Framework
import { Typography } from "ui";
// Components
import ReCaptcha from "react-google-recaptcha";
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ siteKey, size = "normal", form, field }) => {
  const { name } = field;
  const { setFieldValue, touched, errors } = form;
  const fieldError = get(errors, field.name);
  const showError = get(touched, field.name) && !!fieldError;
  // Handlers
  const handleChange = useCallback(
    (recaptcha) => {
      setFieldValue(name, recaptcha || "");
    },
    [setFieldValue, name]
  );
  // Render
  return (
    <div>
      <ReCaptcha size={size} sitekey={siteKey} onChange={handleChange} />
      {showError && (
        <Typography variant="caption" color="error">
          {fieldError}
        </Typography>
      )}
    </div>
  );
};

const FormRecaptchaField = (props) => (
  <FormField component={Component} {...props} />
);

FormRecaptchaField.propTypes = {
  siteKey: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["compact", "normal"]),
};

export default FormRecaptchaField;
