// React
import React from "react";
// Framework
import { forwardType, App } from "@mefisto/web";
// Icon
import { GameIcon } from "icons";
// Loader
import { Loader } from "loader";
// Navigation
import { paths as gamePaths } from "@plugins/game";

export default forwardType(App, () => (
  <App
    enabled
    name="GAME"
    type="USER"
    title="Game"
    color="#5db35c"
    priority={2}
    icon={<GameIcon />}
    loader={<Loader />}
    navigation={{
      basename: "game",
      routes: {
        default: gamePaths.categories,
      },
      paths: {
        ...gamePaths,
      },
    }}
    resource={{
      source: "ui",
    }}
  />
));
