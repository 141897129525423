// React
import React, { useEffect, useState } from "react";
// Helpers
import { replace, includes } from "@mefisto/utils";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const RedirectInterceptor = ({ children }) => {
  // State
  const [finished, setFinished] = useState(false);
  // Effect
  useEffect(() => {
    const { href, host } = window.location;
    if (includes(host, "hablaylenguajeapp")) {
      setFinished(false);
      const url = replace(href, "hablaylenguajeapp", "hablamigo");
      window.location.replace(url);
      return;
    }
    setFinished(true);
  }, []);
  // Render
  return <>{children(finished)}</>;
};

export default RedirectInterceptor;
