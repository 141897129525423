// React
import React from "react";
// Framework
import { forwardType, App } from "@mefisto/web";
// Icon
import { AdminIcon } from "icons";
// Loader
import { Loader } from "loader";
// Navigation
import { paths as billingPaths } from "@plugins/billing";
import { paths as blogPaths } from "@plugins/blog";
import { paths as portalPaths } from "@plugins/portal";
import { paths as supportPaths } from "@plugins/support";

export default forwardType(App, () => (
  <App
    enabled
    name="ADMIN"
    type="USER"
    title="Admin"
    color="#f35448"
    priority={0}
    icon={<AdminIcon />}
    loader={<Loader />}
    logo={{ title: "Admin" }}
    // prettier-ignore
    navigation={{
      basename: "admin",
      routes: {
        default: "/organizations",
      },
      paths: {
        ...billingPaths,
        ...blogPaths,
        ...portalPaths,
        ...supportPaths,
        dashboard: "/dashboard",
        gallery: "/content/gallery",
        sounds: "/content/sounds",
        category: "/categories/:type",
        categorySemantics: "/categories/semantics",
        categoryPragmatics: "/categories/pragmatics",
        categorySyntaxAndMorphology: "/categories/syntax_and_morphology",
        categoryArticulation: "/categories/articulation",
        game: "/categories/:type/games/:game",
      },
    }}
    resource={{
      source: "navigation",
    }}
  />
));
