// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isNumber } from "@mefisto/utils";
// Components
import prettyBytes from "pretty-bytes";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

/**
 * @deprecated Use portal.format.bytes
 */
const FileSizeFormatter = ({ value, children }) => {
  return (
    <React.Fragment>
      {children(isNumber(value) ? prettyBytes(value) : null)}
    </React.Fragment>
  );
};

FileSizeFormatter.propTypes = {
  value: PropTypes.number,
};

export default FileSizeFormatter;
