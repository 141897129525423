// React
import { useEffect, useCallback, useState } from "react";
// Framework
import { useMounted } from "hooks";
import { usePortal } from "stack/core";

export const useApps = () => {
  // Framework
  const { callWhenMounted } = useMounted();
  const { apps } = usePortal();
  // Callback
  const makeResult = useCallback(({ all, organization, user, current }) => {
    return {
      all,
      organization,
      user,
      current,
    };
  }, []);
  // State
  const [result, setResult] = useState(makeResult(apps));
  // Effects
  useEffect(() => {
    return apps.onChange(
      callWhenMounted(() => {
        setResult(makeResult(apps));
      })
    );
  }, [callWhenMounted, makeResult, apps]);
  // Render
  return { ...result };
};
