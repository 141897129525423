// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack";
import { makeStyles, Section, ListItem, ListItemText, Cloak } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  root: {
    width: "auto",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderMenuLink = ({ context = "link", value, title, path, disabled }) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { navigation } = usePortal();
  // Handlers
  const handleSelection = useCallback(() => {
    navigation.goTo(path);
  }, [navigation, path]);
  // Render
  return (
    <Section context={context} value={value}>
      <Cloak variant="remove" enabled={disabled}>
        <ListItem
          dense
          button
          disableRipple
          className={classes.root}
          onClick={handleSelection}
        >
          <ListItemText primary={title} />
        </ListItem>
      </Cloak>
    </Section>
  );
};

HeaderMenuLink.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string,
  disabled: PropTypes.bool,
};

export default HeaderMenuLink;
