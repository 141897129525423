// React
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
// Helpers
import { map } from "@mefisto/utils";
// Framework
import { Grid } from "@material-ui/core";
// Components
import ColorItem from "../ColorItem";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ColorPicker = ({
  colors = [
    "#f44336",
    "#e91e63",
    "#ad1457",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#01579b",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#2e7d32",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#d84315",
    "#795548",
    "#9e9e9e",
    "#607d8b",
    "#37474f",
  ],
  selectedColor,
  onSelection,
}) => {
  // State
  const [currentColor, setCurrentColor] = useState(selectedColor);
  // Handlers
  const handleSelection = useCallback(
    (color) => {
      setCurrentColor(color);
      onSelection(color);
    },
    [onSelection]
  );
  // Render
  return (
    <Grid container spacing={2}>
      {map(colors, (color, key) => (
        <Grid item key={key}>
          <ColorItem
            color={color}
            selected={color === currentColor}
            onSelection={handleSelection}
          />
        </Grid>
      ))}
    </Grid>
  );
};

ColorPicker.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool,
  selectedColor: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ColorPicker;
