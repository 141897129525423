// React
import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty } from "@mefisto/utils";
// Framework
import { ModalDialog, Box } from "ui";
import { useTranslate } from "localization";
import { useEntityReadLazy } from "model/hooks";
import { EntityPropType } from "model/utils";
// Components
import Page from "./components/Page";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelPrintDialog = forwardRef(
  ({ title, entity, tag, fetchPolicy = "network-only" }, ref) => {
    // Framework
    const { t } = useTranslate();
    // State
    const [open, setOpen] = useState(false);
    // Refs
    const pageRef = useRef(null);
    // Model
    const { fetch, data, loading } = useEntityReadLazy(entity, {
      fetchPolicy,
      tags: {
        READ: tag ?? entity.Tags.PRINT,
      },
    });
    // Ref
    useImperativeHandle(ref, () => ({
      open({ input } = {}) {
        setOpen(true);
        fetch({ input });
      },
    }));
    // Handlers
    const handlePrint = () => {
      pageRef.current.print();
    };
    const handleClose = () => {
      setOpen(false);
    };
    // Render
    return (
      <ModalDialog
        fullScreen
        title={title}
        open={open}
        loading={loading}
        actions={[
          {
            title: t("core:model.printDialog.action.print.title"),
            onClick: handlePrint,
          },
        ]}
        onClose={handleClose}
      >
        <Box
          width="100%"
          height="calc(100% - 8px)"
          maxWidth={800}
          margin="auto"
          border="4px dashed"
          borderColor="grey.200"
        >
          {!isEmpty(data) && <Page title={title} html={data} ref={pageRef} />}
        </Box>
      </ModalDialog>
    );
  }
);

ModelPrintDialog.ModelPrintDialog = {
  /**
   * Dialog title
   */
  title: PropTypes.string,
  /**
   * Dialog entity class.
   * All the actions will inherit it.
   */
  entity: EntityPropType,
  /**
   * Print tag.
   * By default, entity.Tags.PRINT tag is used.
   * Use this property to override.
   */
  tag: PropTypes.any,
};

export default ModelPrintDialog;
