// React
import React from "react";
// Framework
import {
  Portal,
  Firebase,
  Navigation,
  Interceptors,
  Language,
  Localization,
  LocalizationInterceptor,
} from "@mefisto/web";
// Apps
import AdminApp from "@apps/admin";
import AuthApp from "@apps/auth";
import GameApp from "@apps/game";
import PortalApp from "@apps/portal";
import PresentationApp from "@apps/presentation";
// Components
import { Externals } from "externals";
import { Helmet } from "helmet";
import { Themes } from "themes";
import { DashboardInterceptor, RedirectInterceptor } from "interceptors";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default () => (
  <Portal>
    <Externals />
    <Helmet />
    <Themes />
    <Firebase
      modules={["analytics", "auth", "database", "messaging", "performance"]}
    />
    <Navigation
      routes={{
        home: "/",
        authentication: "/auth",
        unauthenticated: "/portal/dashboard",
        dashboard: "/portal/dashboard",
        subscription: "/portal/billing/subscription/user",
        profile: "/portal/profile",
        organization: "/portal/organization",
        organizationCreate: "/portal/organization/get-started",
        location: "/portal/locations/:location",
        memberships: "/portal/memberships",
        connections: "/portal/connections",
        notVerified: "/auth/verify",
        notAuthenticated: "/auth/sign-in",
        notAdmin: "/portal",
        notOrganization: "/portal/organization/get-started",
      }}
    />
    <Localization
      languages={[
        <Language locale="en" title="English" />,
        <Language locale="es" title="Español" />,
      ]}
    />
    <Interceptors
      interceptors={[
        <RedirectInterceptor />,
        <LocalizationInterceptor />,
        <DashboardInterceptor />,
      ]}
    />
    {/* Apps */}
    <AdminApp />
    <AuthApp />
    <GameApp />
    <PortalApp />
    <PresentationApp />
  </Portal>
);
