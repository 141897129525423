// React
import React, { useMemo, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles } from "ui";
// Components
import DOMPurify from "dompurify";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "calc(100vh - 186px)",
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: "100%",
    verticalAlign: "baseline",
    overflow: "auto",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Page = forwardRef(({ title, html }, ref) => {
  // Styles
  const classes = useStyles();
  // Memo
  const purify = useMemo(() => DOMPurify(window), []);
  const id = "printing-dialog-page";
  // Refs
  useImperativeHandle(ref, () => ({
    print() {
      const iframe = document.frames
        ? document.frames[id]
        : document.getElementById(id);
      const iframeWindow = iframe.contentWindow || iframe;
      iframe.focus();
      iframeWindow.print();
    },
  }));
  // Render
  return (
    <iframe
      id={id}
      className={classes.root}
      title={title}
      sandbox="allow-same-origin allow-modals"
      referrerPolicy="no-referrer"
      srcDoc={purify.sanitize(html, { FORCE_BODY: true })}
    />
  );
});

Page.propTypes = {
  title: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
};

export default Page;
